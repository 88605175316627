import React, { useEffect } from "react";
import Background from "../Component/Background/Background";
import Button from "../Component/Button/Button";
import PasswordTextfield from "../Component/TextField/PasswordTextfield";
import styles from "./ForgotPassword.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { checkToken, forgotPassword } from "../API/forgot_password";
import { Circles } from "react-loader-spinner";

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword() {
  const [isButtonLoading, setButtonLoader] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [shackPassword, setShackPassword] = React.useState("");
  const [shackConfirmPassword, setShackConfirmPassword] = React.useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmPasswordHandler = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
  };

  async function checkTokenApi(token) {
    const data = await checkToken(token);

    if (data) {
      if (data.data["s"] === 0) {
        navigate("/link-expired");
      }
    }
    setButtonLoader(false);
  }

  useEffect(() => {
    checkTokenApi(token);
  }, []);

  const onClick = async () => {
    if (!password) {
      setShackPassword("shack");
      setTimeout(() => setShackPassword(""), 700);
      toast.warn('Password is Required', {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition : Flip,
        theme: "dark",
      });
    } else if (!confirmPassword) {
      toast.warn('Confirm Password is Required', {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition : Flip,
        theme: "dark",
      });
      setShackConfirmPassword("shack");
      setTimeout(() => setShackConfirmPassword(""), 700);
    } else if (password !== confirmPassword) {
      toast.warn('Password & Confirm Password Dosent Match', {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition : Flip,
        theme: "dark",
      });
      setShackPassword("shack");
      setShackConfirmPassword("shack");
      setTimeout(() => {
        setShackConfirmPassword("");
        setShackPassword("");
      }, 700);
    }else if (password.length < 6) {
      toast.warn('Password Should be greater than six characters', {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition : Flip,
        theme: "dark",
      });
      setShackPassword("shack");
      setShackConfirmPassword("shack");
      setTimeout(() => {
        setShackConfirmPassword("");
        setShackPassword("");
      }, 700); 
    } else {
      setButtonLoader(true);
      const data = await forgotPassword(password, token);
      setButtonLoader(false);
      if (data) {
        if (data.data["s"] === 1) {
          navigate("/changed-password");
        } else {
          navigate("/link-expired");
        }
      }
    }
  };
  return (
    <Background>
      <div className={styles.forgotPassword}>
        {/* logo app */}
        <div className={styles.logoContainer}>
          <img className={styles.logo} src="./logo.png" alt="logo" />
        </div>

        {/* title */}
        <div className={styles.titleContainer}>
          <h1>Change Password ?</h1>
        </div>

        {/* form */}
        <PasswordTextfield
          lable="Password"
          onChange={passwordHandler}
          value={password}
          shack={shackPassword}
        />
        <PasswordTextfield
          lable="Confirm Password"
          onChange={confirmPasswordHandler}
          value={confirmPassword}
          shack={shackConfirmPassword}
        />



        {isButtonLoading ? (
          <div
            className={styles.loader}>
            <Circles
              height="30"
              width="30"
              radius="9"
              color="#F04A2A"
              ariaLabel="three-dots-loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        ) : (
          <Button onClick={onClick}>
            <span>Submit</span>
          </Button>
        )}

        <ToastContainer/>
      </div>
    </Background>
  );
}

export default ForgotPassword;
