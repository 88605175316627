import axios from "axios";

const Api = axios.create({
  baseURL: "https://api.unserecity.de",
});

export const forgotPassword = async (password, token) => {
  const formData = new FormData();
  formData.append("password", password);
  formData.append("email_token", token);

  try {
    const data = await Api.post("api/auth/change-password", formData);
    return data;
  } catch (e) {
    return null;
  }
};

export const checkToken = async (token) => {
  const formData = new FormData();
  formData.append("email_token", token);

  try {
    const data = await Api.post("api/auth/check-email-token", formData);
    return data;
  } catch (e) {
    return null;
  }
};
